<template>
  <section>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      scrollable
      @keydown="executeCloseModalShowRequestMaterial"
      @click:outside="executeCloseModalShowRequestMaterial"
    >
      <v-card v-if="currentRequestMaterials">
        <div class="p-4 pb-0">
          <h5>
            COD
            {{ currentRequestMaterials.project.code }}
            | Tipo de solicitud:
            {{ currentRequestMaterials.material_request_type.name }}
            /
            <b>
              {{
                `ETAPA DEL PROYECTO: ${
                  currentRequestMaterials.stage
                    ? currentRequestMaterials.stage.name
                    : "Sin etapa"
                }`
              }}
            </b>
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-container class="pt-0">
            <div class="d-flex justify-content-end">
              <section class="w-25">
                <v-text-field
                  v-model="filters.filter"
                  class="px-2"
                  clearable
                  placeholder="Buscar:"
                ></v-text-field>
              </section>
            </div>
            <v-data-table
              class="px-2 elevation-4 pt-2"
              :headers="tableHeaders"
              :items="filteredMaterials"
              :items-per-page="200"
              hide-default-footer
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.code="{ item }">
                <div class="d-flex justify-content-start">
                  <v-chip label small color="primary" class="mt-0 text-white">
                    {{ item.code }}
                  </v-chip>
                </div>
              </template>
              <template v-slot:item.type_request="{ item }">
                <span style="font-size: 11px">
                  {{ typesRequests[item.type_request] }}
                </span>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.unit="{ item }">
                <p class="text-center my-0" style="font-size: 11px">
                  {{ item.unit }}
                </p>
              </template>
              <template v-slot:item.sap_code="{ item }">
                <p class="text-left my-0" style="font-size: 11px">
                  {{ item.sap_code }}
                </p>
              </template>
              <template v-slot:item.quantity="{ item }">
                <p class="text-right mt-4" style="font-size: 11px">
                  {{ item.quantity }}
                </p>
              </template>
            </v-data-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalShowRequestMaterial"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalShowRequestMaterials: {
      type: Function,
      default: () => {},
    },
    currentRequestMaterials: {
      default: null,
    },
  },
  data() {
    return {
      currentMaterials: [],
      filters: {
        filter: "",
      },
      typesRequests: {
        InventoryTransferRequest: "Solicitud de Traslado",
        PurchaseRequest: "Solicitud de Compra",
      },
      tableHeaders: [
        {
          text: "Código Material",
          align: "start",
          sortable: false,
          value: "code",
        },
        {
          text: "Descripción",
          class: "text-left",
          sortable: false,
          value: "name",
        },
        {
          text: "Cantidad solicitada",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "unit",
        },
        {
          text: "Código SAP",
          align: "start",
          sortable: false,
          value: "sap_code",
        },
        {
          text: "Tipo de solicitud",
          align: "center",
          sortable: false,
          value: "type_request",
        },
      ],
    };
  },
  methods: {
    ...mapActions({}),
    executeCloseModalShowRequestMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.currentMaterials = [];
        this.closeModalShowRequestMaterials($event);
      }
    },
    setMaterialsCurrentRequest(requests) {
      requests.forEach((request) => {
        this.currentMaterials.push(
          ...request.sap_request_items.map((item) => {
            return {
              ...item.material,
              quantity: item.quantity,
              unit: item.material.measurement_unit.name,
              sap_code: request.sap_number,
              type_request: request.sap_request_type.name,
            };
          })
        );
      });
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      warehouses: "requestMaterialsSap/warehouses",
    }),
    filteredMaterials() {
      if (this.filters.filter) {
        let filter = this.filters.filter.trim().toLowerCase();
        return this.currentMaterials.filter((approveRequest) => {
          return (
            approveRequest.code.toLowerCase().includes(filter) ||
            approveRequest.sap_code.toLowerCase().includes(filter) ||
            approveRequest.name.toLowerCase().includes(filter) ||
            approveRequest.unit.toLowerCase().includes(filter)
          );
        });
      }
      return this.currentMaterials;
    },
  },
  watch: {
    currentRequestMaterials(newRequest) {
      if (newRequest) {
        this.setMaterialsCurrentRequest(newRequest.sap_material_request);
      }
    },
  },
};
</script>

<style></style>
