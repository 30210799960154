var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('v-dialog',{attrs:{"max-width":"1200px","scrollable":""},on:{"keydown":_vm.executeCloseModalRecepcionRequestMaterial,"click:outside":_vm.executeCloseModalRecepcionRequestMaterial},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[(_vm.currentRequestMaterials)?_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" Solicitud de Materiales ")]),_c('h6',{staticClass:"mt-5"},[_vm._v(" COD "+_vm._s(_vm.currentRequestMaterials.project.code)+" | Tipo de solicitud: "+_vm._s(_vm.currentRequestMaterials.material_request_type.name)+" / "),_c('b',[_vm._v(" "+_vm._s(("ETAPA DEL PROYECTO: " + (_vm.currentRequestMaterials.stage ? _vm.currentRequestMaterials.stage.name : "Sin etapa")))+" ")])])]),_c('hr'),_c('v-card-text',[_c('div',{staticClass:"px-3"},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('section',{staticClass:"w-25"},[_c('v-text-field',{staticClass:"px-2",attrs:{"clearable":"","placeholder":"Buscar:"},model:{value:(_vm.filters.filter),callback:function ($$v) {_vm.$set(_vm.filters, "filter", $$v)},expression:"filters.filter"}})],1)]),_c('v-data-table',{staticClass:"px-2 elevation-4 pt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.filteredMaterials,"items-per-page":200,"hide-default-footer":"","loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-start"},[_c('v-chip',{staticClass:"mt-0 text-white",attrs:{"label":"","small":"","color":"primary"}},[_vm._v(" "+_vm._s(item.code)+" ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-left my-0",staticStyle:{"font-size":"11px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.requested",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"text-right my-0"},[_vm._v(" "+_vm._s(item.requested)+" ")])]}},{key:"item.received",fn:function(ref){
var item = ref.item;
return [(item.received == null)?_c('v-btn',{attrs:{"color":"primary darken-1","outlined":"","small":""},on:{"click":function($event){return _vm.openModalUpdateReceptionMaterial(
                    item,
                    _vm.actionsToReceptionMaterial.received
                  )}}},[_c('small',[_vm._v(" Completar ")])]):_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.received)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(item.consumed == null)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.openModalUpdateReceptionMaterial(
                          item,
                          _vm.actionsToReceptionMaterial.received,
                          Number(item.received)
                        )}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-pencil ")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1)]}},{key:"item.consumed",fn:function(ref){
                        var item = ref.item;
return [(item.received && item.consumed == null)?_c('v-btn',{attrs:{"color":"primary darken-1","outlined":"","small":""},on:{"click":function($event){return _vm.openModalUpdateReceptionMaterial(
                    item,
                    _vm.actionsToReceptionMaterial.consumed
                  )}}},[_c('small',[_vm._v(" Completar ")])]):(item.consumed)?_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('p',{staticClass:"my-0"},[_vm._v(" "+_vm._s(item.consumed)+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){_vm.openModalUpdateReceptionMaterial(
                          item,
                          _vm.actionsToReceptionMaterial.consumed,
                          Number(item.consumed)
                        )}}},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])],1):_vm._e()]}},{key:"item.returned",fn:function(ref){
                        var item = ref.item;
return [(item.consumed !== null && item.returned == null)?_c('v-btn',{attrs:{"color":"primary darken-1","outlined":"","small":""},on:{"click":function($event){return _vm.openModalUpdateReceptionMaterial(
                    item,
                    _vm.actionsToReceptionMaterial.returned
                  )}}},[_c('small',[_vm._v(" Completar ")])]):_c('span',[_vm._v(" "+_vm._s(item.returned)+" ")])]}}],null,false,3455019897)})],1)]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","outlined":""},on:{"click":_vm.executeCloseModalRecepcionRequestMaterial}},[_c('small',[_vm._v(" Cerrar ")])])],1)],1):_vm._e()],1),_c('update-reception-material',{ref:"updateReceptionMaterial",attrs:{"modalAction":_vm.dialogUpdateReceptionMaterial,"closeModalUpdateReceptionMaterial":_vm.closeModalUpdateReceptionMaterial,"currentMaterial":_vm.currentMaterialToUpdate,"action":_vm.currentAction},on:{"actionUpdated":_vm.updateReceptionCurrentMaterial,"commentUpdated":_vm.updateCommentCurrentMaterial}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }