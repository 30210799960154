<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="600px"
      scrollable
      @keydown="executeCloseModalUpdateReceptionMaterial"
      @click:outside="executeCloseModalUpdateReceptionMaterial"
    >
      <v-card v-if="currentMaterial">
        <div class="p-4 pb-0">
          <h5>
            {{ getNameFromAction() }}
          </h5>
        </div>
        <hr />
        <v-card-text>
          <v-form>
            <v-col cols="12" md="12" sm="12">
              <label for="" class="font-weight-bold">
                Código Producto
              </label>
              <p>
                {{ currentMaterial.code }}
              </p>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label for="" class="font-weight-bold">
                Nombre Producto
              </label>
              <p>
                {{ currentMaterial.name }}
              </p>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <label for="" class="font-weight-bold">
                Cantidad Solicitada
              </label>
              <p>
                {{ currentMaterial.requested }}
              </p>
            </v-col>

            <v-col cols="12" md="12" sm="12">
              <label for="" class="font-weight-bold">
                Cantidad Recepcionada
              </label>
              <v-text-field
                v-if="action == actionsToReceptionMaterial.received"
                v-model="form.quantity"
                type="number"
                clearable
                outlined
                :min="1"
              ></v-text-field>
              <p v-else>
                {{ currentMaterial.received }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              v-if="
                action == actionsToReceptionMaterial.consumed ||
                  action == actionsToReceptionMaterial.returned
              "
            >
              <label for="" class="font-weight-bold">
                Cantidad Consumida
              </label>
              <v-text-field
                v-if="action == actionsToReceptionMaterial.consumed"
                v-model="form.quantity"
                type="number"
                outlined
                clearable
                :min="1"
              ></v-text-field>
              <p v-else>
                {{ currentMaterial.consumed }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="12"
              sm="12"
              v-if="action == actionsToReceptionMaterial.returned"
            >
              <label for="" class="font-weight-bold">
                Cantidad Devuelta
              </label>
              <v-text-field
                v-model="form.quantity"
                type="number"
                clearable
                outlined
                hide-details
                :min="1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" sm="12" class="pt-0 mt-0">
              <label for="" class="font-weight-bold">
                Comentario Adicional
              </label>
              <v-textarea
                v-model="form.comment"
                clearable
                outlined
                rows="2"
              ></v-textarea>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red darken-1"
            outlined
            @click="executeCloseModalUpdateReceptionMaterial"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
          <v-btn
            :disabled="loading"
            color="teal accent-4"
            class="text-white"
            @click="updateReceptionCurrentMaterial"
          >
            <small>
              Guardar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import { ACTIONS_TO_RECEPTION_MATERIAL } from "@/constants/requestMaterial";
import swal2 from "sweetalert2";

export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalUpdateReceptionMaterial: {
      type: Function,
      default: () => {},
    },
    currentMaterial: {
      default: null,
    },
    action: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      actionsToReceptionMaterial: ACTIONS_TO_RECEPTION_MATERIAL,
      form: {
        quantity: null,
        comment: null,
      },
    };
  },
  methods: {
    ...mapActions({
      updateReceptionFromMaterialRequestId:
        "requestMaterialsSap/updateReceptionFromMaterialRequestId",
      updateCommentFromMaterialRequestId:
        "requestMaterialsSap/updateCommentFromMaterialRequestId",
    }),
    executeCloseModalUpdateReceptionMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.resetForm();
        this.closeModalUpdateReceptionMaterial($event);
      }
    },
    getNameFromAction() {
      switch (this.action) {
        case this.actionsToReceptionMaterial.received:
          return "Recepción de producto";
        case this.actionsToReceptionMaterial.consumed:
          return "Vale de Consumo";
        case this.actionsToReceptionMaterial.returned:
          return "Devolución";
        default:
          return "Cargando..";
      }
    },
    async updateReceptionCurrentMaterial() {
      if (!this.form.quantity) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Debe ingresar la cantidad",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }

      this.loading = true;

      const payload = {
        material_request_item_id: this.currentMaterial.id,
        field: this.action,
        quantity: this.form.quantity,
      };

      const response = await this.updateReceptionFromMaterialRequestId(payload);

      if (response.status == 200 || response.status == 201) {
        if (this.form.comment) {
          const responseComment = await this.updateCommentFromMaterialRequestId(
            {
              material_request_item_id: payload.material_request_item_id,
              comment: this.form.comment,
            }
          );
        }
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Cantidad actualizada con exito",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        this.$emit("actionUpdated", {
          id: this.currentMaterial.id,
          action: this.action,
          quantity: this.form.quantity,
        });
        if (this.form.comment) {
          this.$emit("commentUpdated", {
            id: this.currentMaterial.id,
            comment: this.form.comment,
          });
        }
        this.resetForm();
      }
      if (response.status == 400) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: response.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 7000,
          timerProgressBar: true,
        });
        this.loading = false;
        return;
      }
      if (response.status == 500) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 7000,
          timerProgressBar: true,
        });
        this.loading = false;
        return;
      }
      this.loading = false;
      this.closeModalUpdateReceptionMaterial(true);
    },
    resetForm() {
      this.form.quantity = null;
      this.form.comment = "";
    },
  },
};
</script>

<style></style>
