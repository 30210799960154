<template>
  <section>
    <loading-flux :value="loading"></loading-flux>
    <v-dialog
      v-model="modalAction"
      max-width="1200px"
      scrollable
      @keydown="executeCloseModalCreateRequestMaterial"
      @click:outside="executeCloseModalCreateRequestMaterial"
    >
      <v-card>
        <div class="p-4 pb-0">
          <h5>
            Agregar Solicitud
          </h5>
        </div>
        <hr />
        <v-spacer></v-spacer>
        <v-card-text>
          <v-container>
            <v-row align-content="center">
              <v-col cols="12" md="6" sm="12">
                <label
                  class="font-weight-bold"
                  v-if="!$route.query.sap_project && !stockItemGroup"
                >
                  Destino
                </label>
                <v-select
                  v-if="!$route.query.sap_project && !stockItemGroup"
                  v-model="form.type_destiny"
                  :items="TYPES_DESTINY_REQUEST_MATERIALS"
                  item-text="name"
                  item-value="id"
                  @input="getMaterialsFromTypeDestiny()"
                ></v-select>
                <div
                  class="form-ceco mt-3"
                  v-if="form.type_destiny == TYPE_DESTINY_CECO_ID"
                >
                  <label class="font-weight-bold">
                    CECO
                  </label>
                  <v-autocomplete
                    v-model="form.ceco"
                    :items="cecos.items ? cecos.items : []"
                    item-text="name_code"
                    @update:search-input="getCecosData"
                    @click:clear="getCecosData"
                    no-data-text="Sin CECOS"
                    :loading="loadingCecos"
                  ></v-autocomplete>
                </div>
                <div class="form_project">
                  <label
                    :class="{
                      'mt-3': !stockItemGroup,
                    }"
                    v-if="
                      form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
                        stockItemGroup
                    "
                    class="font-weight-bold"
                  >
                    Proyecto
                  </label>
                  <v-autocomplete
                    v-if="
                      form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
                        stockItemGroup
                    "
                    v-model="form.project"
                    clearable
                    :items="
                      projects.items
                        ? projects.items.filter((item) => item.warehouse)
                        : []
                    "
                    item-text="name_code"
                    @input="getStagesFromProject()"
                    @update:search-input="getProjectsData"
                    @click:clear="getProjectsData"
                    no-data-text="Sin proyectos"
                    :loading="loadingProjects"
                  ></v-autocomplete>
                  <section
                    v-if="
                      ((form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
                        stockItemGroup) &&
                        form.project &&
                        !loadingProjects &&
                        getCurrentProjectFromCode) ||
                        ($route.query.sap_project && getCurrentProjectFromCode)
                    "
                  >
                    <label class="font-weight-bold">
                      Etapa del proyecto
                    </label>
                    <v-autocomplete
                      v-model="form.sap_stage"
                      :items="availableStagesProjects"
                      :disabled="
                        !availableStagesProjects ||
                          !availableStagesProjects.length ||
                          statusAvailableProjects === 404
                      "
                      item-value="sap_code"
                      item-text="name"
                    >
                    </v-autocomplete>

                    <p
                      class="text-danger"
                      v-if="
                        !availableStagesProjects ||
                          !availableStagesProjects.length ||
                          statusAvailableProjects === 404
                      "
                    >
                      <b>Este proyecto no tiene etapas de proyecto activas</b>
                    </p>

                    <label class="font-weight-bold">
                      Unidad de Negocio
                    </label>
                    <p style="font-size: 12px">
                      {{ getCurrentProjectFromCode.business_unit.name }}
                    </p>
                    <label class="font-weight-bold">
                      Tipo de Venta
                    </label>
                    <p style="font-size: 12px">
                      {{ getCurrentProjectFromCode.sale_type }}
                    </p>
                  </section>
                </div>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <label class="font-weight-bold">
                  Tipo de Solicitud de Materiales
                </label>
                <v-select
                  clearable
                  v-model="form.request_type"
                  :items="typeRequests.map((item) => item.name)"
                ></v-select>
                <p class="text-info">
                  <b>IMPORTANTE</b>: En este selector puedes cambiar el tipo de
                  solicitud de materiales que deseas crear
                </p>
              </v-col>
            </v-row>
            <div
              class="d-flex flex-column justify-content-center align-items-end my-5"
            >
              <div>
                <v-btn
                  small
                  :disabled="
                    !form.type_destiny &&
                      !$route.query.sap_project &&
                      !stockItemGroup
                  "
                  color="teal accent-4 text-white"
                  class=""
                  @click="openModalTableAddMaterials"
                >
                  Agregar Materiales
                </v-btn>
              </div>
              <small
                v-if="
                  !form.type_destiny &&
                    !$route.query.sap_project &&
                    !stockItemGroup
                "
                class="text-warning mt-2"
              >
                Selecciona el destino de la solicitud para agregar los
                materiales
              </small>
            </div>
            <v-data-table
              class="px-2 elevation-4 mt-5 pt-2"
              :headers="tableHeaders"
              :items="currentMaterials"
              :items-per-page="filters.items !== 'Todos' ? filters.items : 100"
              :page.sync="filters.page"
              hide-default-footer
              loading-text="Cargando materiales"
              item-key="id"
              no-data-text="Sin materiales"
              @page-count="pageCount = $event"
            >
              <template v-slot:item.quantity="{ item }">
                <v-text-field
                  type="number"
                  :min="1"
                  class="pt-0 mt-0"
                  v-model="item.quantity"
                  hide-details
                ></v-text-field>
              </template>
              <template v-slot:item.code="{ item }">
                <p class="my-0 text-left" style="font-size: 12px;">
                  {{ item.code }}
                </p>
              </template>
              <template v-slot:item.name="{ item }">
                <p class="my-0 text-left" style="font-size: 12px;">
                  {{ item.name }}
                </p>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click="deleteMaterial(item)"
                      icon
                      small
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar Material</span>
                </v-tooltip>
              </template>
            </v-data-table>
            <v-col cols="12" md="12" sm="12" class="mt-5 pt-5">
              <label for="comments" class="mt-4">
                Comentario solicitud
              </label>
              <v-textarea
                rows="4"
                counter
                outlined
                v-model="form.description"
              ></v-textarea>
            </v-col>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="loading"
            color=""
            @click="executeCloseModalCreateRequestMaterial"
          >
            <small>
              Cerrar
            </small>
          </v-btn>
          <v-btn
            :disabled="loading"
            color="teal accent-4"
            class="text-white"
            @click="createRequestMaterials"
          >
            <small>
              Guardar
            </small>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <table-add-materials
      ref="tableAddMaterials"
      :modalAction="dialogTableAddMaterials"
      :closeModalTableAddMaterials="closeModalTableAddMaterials"
      @addMaterialsSelected="addMaterialsSelected"
    ></table-add-materials>
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { authUsuarioComputed } from "@/state/helpers";
import { preventCloseIfNotKeyEventEsc } from "@/helpers/common";
import {
  TYPE_REQUESTS,
  TYPE_REQUESTS_MATERIALS,
  TYPES_DESTINY_REQUEST_MATERIALS,
  TYPE_DESTINY_PROJECT_ID,
  TYPE_DESTINY_CECO_ID,
} from "@/constants/requestMaterial";
import TableAddMaterials from "./TableAddMaterials.vue";
import swal2 from "sweetalert2";
export default {
  props: {
    modalAction: {
      type: Boolean,
      default: false,
    },
    closeModalCreateRequestMaterials: {
      type: Function,
      default: () => {},
    },
    typeRequest: {
      type: Number,
      default: TYPE_REQUESTS_MATERIALS.standard,
    },
    /*Prop para solicitudes con grupo de articulos Existencia (Solicitud de Materiales) o
    Solicitudes con grupo de articulos de NO Existencia (Solicitud de Servicios)*/
    stockItemGroup: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    TableAddMaterials,
  },
  data() {
    return {
      TYPES_DESTINY_REQUEST_MATERIALS,
      TYPE_DESTINY_PROJECT_ID,
      TYPE_DESTINY_CECO_ID,
      typeRequests: TYPE_REQUESTS,
      dialogTableAddMaterials: false,
      loading: false,
      loadingProjects: false,
      loadingCecos: false,
      debounce: null,
      debounceCecos: null,
      debounceTime: 500,
      currentMaterials: [],
      filters: {
        page: 1,
        items: 50,
        filter: "",
      },
      form: {
        request_type: null,
        type_destiny: null,
        description: "",
        project: null,
        ceco: null,
        sap_stage: null,
      },
      tableHeaders: [
        {
          text: "Acción",
          align: "center",
          width: "5%",
          sortable: false,
          value: "actions",
        },
        {
          text: "Código",
          align: "center",
          width: "10%",
          sortable: false,
          value: "code",
        },
        {
          text: "Producto",
          align: "start",
          sortable: false,
          value: "name",
        },
        {
          text: "Cantidad",
          align: "center",
          sortable: false,
          width: "10%",
          value: "quantity",
        },
        {
          text: "Unidad",
          align: "center",
          sortable: false,
          value: "unit",
        },
      ],
      statusAvailableProjects: null,
    };
  },
  mounted() {
    if (this.$route.query.sap_project) {
      this.getProjectsData(this.$route.query.sap_project);
    }
    if (this.stockItemGroup) {
      this.form.type_destiny = TYPE_DESTINY_PROJECT_ID;
      this.getMaterialsFromTypeDestiny();
    }
  },
  methods: {
    ...mapActions({
      sendCreateRequestMaterials: "requestMaterialsSap/createRequestMaterial",
      sendCreateRequestMaterialCostCenter:
        "requestMaterialsSap/createRequestMaterialCostCenter",
      getProjects: "requestMaterialsSap/getProjects",
      getCecos: "requestMaterialsSap/getCecos",
      clearAvailableStages: "requestMaterialsSap/clearAvailableStages",
      getStagesAvailableFromProject:
        "requestMaterialsSap/getStagesAvailableFromProject",
    }),
    async getMaterialsFromTypeDestiny() {
      this.currentMaterials = [];
      this.$refs.tableAddMaterials.updateTypeDestiny(this.form.type_destiny);
      this.$refs.tableAddMaterials.getMaterials();
    },
    async getStagesFromProject() {
      if (this.form.project || this.$route.query.sap_project) {
        const res = await this.getStagesAvailableFromProject({
          project_code: this.$route.query.sap_project
            ? this.$route.query.sap_project
            : this.form.project?.split(" - ")[0],
        });
        this.statusAvailableProjects = res.status;
        this.form.sap_stage = null;
      } else {
        this.form.sap_stage = null;
        this.clearAvailableStages();
      }
    },
    async getProjectsData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.form.project !== search)
      ) {
        this.loadingProjects = true;
        clearTimeout(this.debounce);
        let self = this;
        this.debounceProjects = setTimeout(async function() {
          await self.getProjects({
            is_mobile:
              self.typeRequest == TYPE_REQUESTS_MATERIALS.mobile_warehouses
                ? true
                : undefined,
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingProjects = false;
        }, self.debounceTime);
      }
    },
    async getCecosData(search = "") {
      if (
        (search && search.type) ||
        ((search || typeof search == "string") && this.form.ceco !== search)
      ) {
        this.loadingCecos = true;
        clearTimeout(this.debounceCecos);
        let self = this;
        this.debounceCecos = setTimeout(async function() {
          await self.getCecos({
            filter: search && !search.type ? search : undefined,
            items: 20,
          });
          self.loadingCecos = false;
        }, self.debounceTime);
      }
    },
    async createRequestMaterials() {
      if (
        !this.form.type_destiny &&
        !this.$route.query.sap_project &&
        !this.stockItemGroup
      ) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Destino es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (
        (this.form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
          this.$route.query.sap_project) &&
        !this.form.sap_stage
      ) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "La etapa es requerida",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (
        this.form.type_destiny == TYPE_DESTINY_PROJECT_ID &&
        !this.form.project &&
        !this.$route.query.sap_project
      ) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Proyecto y etapa son requeridos",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (this.form.type_destiny == TYPE_DESTINY_CECO_ID && !this.form.ceco) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El CECO es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.form.request_type) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "El Tipo de Solicitud es requerido",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (!this.currentMaterials.length) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "La Solicitud debe tener al menos un material",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      if (this.currentMaterials.find((item) => item.quantity < 1)) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Todos los Materiales deben tener al menos 1 unidad",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        return;
      }
      this.loading = true;
      let response = null;
      let payload = {
        request_type: this.form.request_type,
        description: this.form.description,
        project: this.$route.query.sap_project
          ? this.$route.query.sap_project
          : this.form.type_destiny == TYPE_DESTINY_CECO_ID
          ? undefined
          : this.form.project.split(" - ")[0],
        cost_center:
          this.form.type_destiny == TYPE_DESTINY_CECO_ID
            ? this.form.ceco.split(" - ")[0]
            : undefined,
        materials: this.currentMaterials.reduce(
          (finalObject, currentObject) => {
            return {
              ...finalObject,
              [currentObject.code]: finalObject[currentObject.code]
                ? Number(finalObject[currentObject.code]) +
                  Number(currentObject.quantity)
                : Number(currentObject.quantity),
            };
          },
          {}
        ),
        user: this.user.email, //leoramirez322@gmail.com,
        origin:
          this.form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
          this.$route.query.sap_project ||
          this.stockItemGroup
            ? "BODEGA FLUXSOLAR"
            : undefined,
        sap_stage:
          this.form.type_destiny == TYPE_DESTINY_PROJECT_ID ||
          this.$route.query.sap_project ||
          this.stockItemGroup
            ? this.form.sap_stage
            : undefined,
      };
      if (this.form.type_destiny == TYPE_DESTINY_CECO_ID) {
        response = await this.sendCreateRequestMaterialCostCenter(payload);
      } else {
        response = await this.sendCreateRequestMaterials(payload);
      }
      if (response.status == 200) {
        swal2.fire({
          icon: "success",
          title: "Excelente",
          text: "Solicitud de materiales creada con éxito",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
        this.$refs.tableAddMaterials.resetFilter();
        this.$emit("requestMaterialsCreated");
        this.currentMaterials = [];
        this.form.project = null;
        this.form.request_type = null;
        this.form.description = null;
      }
      if (!response || response.status == 500) {
        swal2.fire({
          icon: "error",
          title: "Error",
          text: "Ha ocurrido un error en el servidor, intentalo nuevamente",
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      if (response.status == 400) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: response.data.detail,
          position: "top-end",
          showConfirmButton: false,
          toast: true,
          timer: 4000,
          timerProgressBar: true,
        });
      }
      this.loading = false;
      this.closeModalCreateRequestMaterials(true);
    },
    addMaterialsSelected(materials) {
      this.currentMaterials.push(
        ...materials.map((item) => {
          return {
            quantity: item.quantity,
            code: item.code,
            name: item.name,
            unit: item.unit,
          };
        })
      );
    },
    openModalTableAddMaterials() {
      this.dialogTableAddMaterials = true;
    },
    closeModalTableAddMaterials($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.$refs.tableAddMaterials.resetFilter();
        this.dialogTableAddMaterials = false;
      }
    },
    deleteMaterial(material) {
      let self = this;
      swal2
        .fire({
          icon: "error",
          title: `¿Deseas eliminar este material: ${material.name}`,
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonColor: "red",
          confirmButtonText: "Eliminar",
          cancelButtonText: `Cancelar`,
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            self.currentMaterials = self.currentMaterials.filter((item) => {
              return item.code !== material.code;
            });
            swal2.fire({
              icon: "success",
              title: "Excelente",
              text: "Material eliminado correctamente",
              position: "top-end",
              showConfirmButton: false,
              toast: true,
              timer: 6000,
              timerProgressBar: true,
            });
          }
        });
    },
    executeCloseModalCreateRequestMaterial($event) {
      let validationEvent = preventCloseIfNotKeyEventEsc($event);
      if (validationEvent) {
        this.filters.filter = "";
        this.closeModalCreateRequestMaterials($event);
      }
    },
    resetFormCreateRequestMaterial() {
      this.form = {
        request_type: null,
        description: "",
        project: null,
        sap_stage: null,
      };
      this.currentMaterials = [];
    },
  },
  computed: {
    ...authUsuarioComputed,
    ...mapGetters({
      projects: "requestMaterialsSap/projects",
      cecos: "requestMaterialsSap/cecos",
      availableStagesProjects: "requestMaterialsSap/availableStagesProjects",
    }),
    getCurrentProjectFromCode() {
      if (
        (this.form.project || this.$route.query.sap_project) &&
        this.projects.items
      ) {
        return this.projects.items.find(
          (item) =>
            item.code == this.form.project?.split(" - ")[0] ||
            item.code == this.$route.query.sap_project
        );
      }
      return null;
    },
  },
  watch: {
    projects(newProjects) {
      if (newProjects.items && this.$route.query.sap_project) {
        this.getStagesFromProject();
      }
    },
  },
};
</script>

<style></style>
