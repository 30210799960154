var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('loading-flux',{attrs:{"value":_vm.loading}}),_c('v-dialog',{attrs:{"max-width":"1200px","scrollable":""},on:{"keydown":_vm.executeCloseModalCreateRequestMaterial,"click:outside":_vm.executeCloseModalCreateRequestMaterial},model:{value:(_vm.modalAction),callback:function ($$v) {_vm.modalAction=$$v},expression:"modalAction"}},[_c('v-card',[_c('div',{staticClass:"p-4 pb-0"},[_c('h5',[_vm._v(" Agregar Solicitud ")])]),_c('hr'),_c('v-spacer'),_c('v-card-text',[_c('v-container',[_c('v-row',{attrs:{"align-content":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[(!_vm.$route.query.sap_project && !_vm.stockItemGroup)?_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Destino ")]):_vm._e(),(!_vm.$route.query.sap_project && !_vm.stockItemGroup)?_c('v-select',{attrs:{"items":_vm.TYPES_DESTINY_REQUEST_MATERIALS,"item-text":"name","item-value":"id"},on:{"input":function($event){return _vm.getMaterialsFromTypeDestiny()}},model:{value:(_vm.form.type_destiny),callback:function ($$v) {_vm.$set(_vm.form, "type_destiny", $$v)},expression:"form.type_destiny"}}):_vm._e(),(_vm.form.type_destiny == _vm.TYPE_DESTINY_CECO_ID)?_c('div',{staticClass:"form-ceco mt-3"},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" CECO ")]),_c('v-autocomplete',{attrs:{"items":_vm.cecos.items ? _vm.cecos.items : [],"item-text":"name_code","no-data-text":"Sin CECOS","loading":_vm.loadingCecos},on:{"update:search-input":_vm.getCecosData,"click:clear":_vm.getCecosData},model:{value:(_vm.form.ceco),callback:function ($$v) {_vm.$set(_vm.form, "ceco", $$v)},expression:"form.ceco"}})],1):_vm._e(),_c('div',{staticClass:"form_project"},[(
                    _vm.form.type_destiny == _vm.TYPE_DESTINY_PROJECT_ID ||
                      _vm.stockItemGroup
                  )?_c('label',{staticClass:"font-weight-bold",class:{
                    'mt-3': !_vm.stockItemGroup,
                  }},[_vm._v(" Proyecto ")]):_vm._e(),(
                    _vm.form.type_destiny == _vm.TYPE_DESTINY_PROJECT_ID ||
                      _vm.stockItemGroup
                  )?_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.projects.items
                      ? _vm.projects.items.filter(function (item) { return item.warehouse; })
                      : [],"item-text":"name_code","no-data-text":"Sin proyectos","loading":_vm.loadingProjects},on:{"input":function($event){return _vm.getStagesFromProject()},"update:search-input":_vm.getProjectsData,"click:clear":_vm.getProjectsData},model:{value:(_vm.form.project),callback:function ($$v) {_vm.$set(_vm.form, "project", $$v)},expression:"form.project"}}):_vm._e(),(
                    ((_vm.form.type_destiny == _vm.TYPE_DESTINY_PROJECT_ID ||
                      _vm.stockItemGroup) &&
                      _vm.form.project &&
                      !_vm.loadingProjects &&
                      _vm.getCurrentProjectFromCode) ||
                      (_vm.$route.query.sap_project && _vm.getCurrentProjectFromCode)
                  )?_c('section',[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Etapa del proyecto ")]),_c('v-autocomplete',{attrs:{"items":_vm.availableStagesProjects,"disabled":!_vm.availableStagesProjects ||
                        !_vm.availableStagesProjects.length ||
                        _vm.statusAvailableProjects === 404,"item-value":"sap_code","item-text":"name"},model:{value:(_vm.form.sap_stage),callback:function ($$v) {_vm.$set(_vm.form, "sap_stage", $$v)},expression:"form.sap_stage"}}),(
                      !_vm.availableStagesProjects ||
                        !_vm.availableStagesProjects.length ||
                        _vm.statusAvailableProjects === 404
                    )?_c('p',{staticClass:"text-danger"},[_c('b',[_vm._v("Este proyecto no tiene etapas de proyecto activas")])]):_vm._e(),_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Unidad de Negocio ")]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getCurrentProjectFromCode.business_unit.name)+" ")]),_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Tipo de Venta ")]),_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getCurrentProjectFromCode.sale_type)+" ")])],1):_vm._e()],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('label',{staticClass:"font-weight-bold"},[_vm._v(" Tipo de Solicitud de Materiales ")]),_c('v-select',{attrs:{"clearable":"","items":_vm.typeRequests.map(function (item) { return item.name; })},model:{value:(_vm.form.request_type),callback:function ($$v) {_vm.$set(_vm.form, "request_type", $$v)},expression:"form.request_type"}}),_c('p',{staticClass:"text-info"},[_c('b',[_vm._v("IMPORTANTE")]),_vm._v(": En este selector puedes cambiar el tipo de solicitud de materiales que deseas crear ")])],1)],1),_c('div',{staticClass:"d-flex flex-column justify-content-center align-items-end my-5"},[_c('div',[_c('v-btn',{attrs:{"small":"","disabled":!_vm.form.type_destiny &&
                    !_vm.$route.query.sap_project &&
                    !_vm.stockItemGroup,"color":"teal accent-4 text-white"},on:{"click":_vm.openModalTableAddMaterials}},[_vm._v(" Agregar Materiales ")])],1),(
                !_vm.form.type_destiny &&
                  !_vm.$route.query.sap_project &&
                  !_vm.stockItemGroup
              )?_c('small',{staticClass:"text-warning mt-2"},[_vm._v(" Selecciona el destino de la solicitud para agregar los materiales ")]):_vm._e()]),_c('v-data-table',{staticClass:"px-2 elevation-4 mt-5 pt-2",attrs:{"headers":_vm.tableHeaders,"items":_vm.currentMaterials,"items-per-page":_vm.filters.items !== 'Todos' ? _vm.filters.items : 100,"page":_vm.filters.page,"hide-default-footer":"","loading-text":"Cargando materiales","item-key":"id","no-data-text":"Sin materiales"},on:{"update:page":function($event){return _vm.$set(_vm.filters, "page", $event)},"page-count":function($event){_vm.pageCount = $event}},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
              var item = ref.item;
return [_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"type":"number","min":1,"hide-details":""},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.code",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"my-0 text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.code)+" ")])]}},{key:"item.name",fn:function(ref){
              var item = ref.item;
return [_c('p',{staticClass:"my-0 text-left",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","color":"red"},on:{"click":function($event){return _vm.deleteMaterial(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Material")])])]}}])}),_c('v-col',{staticClass:"mt-5 pt-5",attrs:{"cols":"12","md":"12","sm":"12"}},[_c('label',{staticClass:"mt-4",attrs:{"for":"comments"}},[_vm._v(" Comentario solicitud ")]),_c('v-textarea',{attrs:{"rows":"4","counter":"","outlined":""},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading,"color":""},on:{"click":_vm.executeCloseModalCreateRequestMaterial}},[_c('small',[_vm._v(" Cerrar ")])]),_c('v-btn',{staticClass:"text-white",attrs:{"disabled":_vm.loading,"color":"teal accent-4"},on:{"click":_vm.createRequestMaterials}},[_c('small',[_vm._v(" Guardar ")])])],1)],1)],1),_c('table-add-materials',{ref:"tableAddMaterials",attrs:{"modalAction":_vm.dialogTableAddMaterials,"closeModalTableAddMaterials":_vm.closeModalTableAddMaterials},on:{"addMaterialsSelected":_vm.addMaterialsSelected}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }